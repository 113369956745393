import { useNavigate } from "react-router-dom";
import "./navbar.css";
import logo from "../assets/adani/logo.png";

function Navbar() {
  const navigate = useNavigate();
  return (
    <div className="mt-8 ml-8" onClick={() => navigate("/")}>
      <img
        src={logo}
        alt=""
        width="50%"
        onClick={() => navigate("/")}
        style={{ display: "none" }}
      />
    </div>
  );
}

export default Navbar;
