import "./button.css";

function Button({ label, onClick }) {
  return (
    <div className="btn" onClick={onClick}>
      {label}
    </div>
  );
}

export default Button;
