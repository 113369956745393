import { toast } from "react-toastify";
import { convertBase64ToFile } from "../utils";

export const registerUserService = async (data) => {
  console.log(data);
  const file = convertBase64ToFile(data?.file, "userImage.jpg");
  const url = import.meta.env.VITE_API_URL;

  const formData = new FormData();
  formData.append("image", file);
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);

  try {
    const response = await fetch(`${url}/user`, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
    toast("Server is down, please try again later");
  }
};
