import { useLocation, useNavigate } from "react-router-dom";

import ty from "../assets/adani/ty.png";

export default function Thankyou() {
  const navigate = useNavigate();
  const location = useLocation();
  const { s3_link } = location.state || {};

  const handleTryAgainClick = () => {
    navigate("/camera");
  };

  return (
    <div className="flex flex-col items-center h-screen ">
      <div className="flex items-center justify-center flex-col gap-5 m-auto p-8 ">
        <div className="flex justify-center items-center">
          {s3_link && (
            <img
              src={s3_link}
              alt="Generated Image"
              onClick={handleTryAgainClick}
              className="cursor-pointer w-2/5 object-contain mb-5 rounded-lg"
            />
          )}
        </div>
        <div className="w-full flex flex-col items-center justify-center">
          <img
            src={ty}
            alt="Thank You"
            width="45%"
            onClick={() => navigate("/")}
          />
        </div>
      </div>

      <div className="flex justify-center items-center flex-col mt-8 gap-8"></div>
    </div>
  );
}
