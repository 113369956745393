import React, { useEffect } from "react";
import holdingScreen from "./assets/adani/holding-screen.jpeg";

function Latest() {
  const [data, setData] = React.useState(null);
  const [displayImage, setDisplayImage] = React.useState(null);
  const [timer, setTimer] = React.useState(null);
  const [isFading, setIsFading] = React.useState(false);

  const handleApi = async () => {
    const baseUrl = import.meta.env.VITE_API_URL;

    try {
      const response = await fetch(`${baseUrl}/user`, {
        method: "GET",
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const resultData = await response.json();

      const outputLink = resultData?.result[0]?.imageUrl;

      if (outputLink && outputLink !== data) {
        setData(outputLink);
        setIsFading(true);
        setTimeout(() => {
          setDisplayImage(outputLink);
          setIsFading(false);
        }, 300); // Delay matches the fade transition duration
        resetTimer();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const resetTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    const newTimer = setTimeout(() => {
      setIsFading(true);
      setTimeout(() => {
        setDisplayImage(null);
        setIsFading(false);
      }, 300); // Delay matches the fade transition duration
    }, 60000);
    setTimer(newTimer);
  };

  useEffect(() => {
    handleApi();
    const interval = setInterval(() => {
      handleApi();
    }, 5000);

    return () => {
      clearInterval(interval);
      if (timer) clearTimeout(timer);
    };
  }, [data]);

  return (
    <div
      className="h-screen w-screen flex items-center justify-center"
      style={{
        backgroundImage: `url( ${holdingScreen})`,
      }}
    >
      <img
        src={displayImage || holdingScreen}
        alt={displayImage ? "Generated Image" : "Holding Screen"}
        className={`w-full h-full object-cover transition-opacity duration-900 ${
          isFading ? "opacity-0" : "opacity-100"
        }`}
      />
    </div>
  );
}

export default Latest;
