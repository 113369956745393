import "./input.css";

export const Input = ({
  label,
  placeholder,
  value,
  onChange,
  name,
  helperText,
  required,
  onClick,
}) => {
  if (name === "gender") {
    return (
      <div className="flex flex-col w-full">
        <label>
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
        <select
          value={value}
          onChange={(e) => onChange(e.target.name, e.target.value)}
          name={name}
          className="input"
        >
          <option value="" disabled selected>
            Select Gender
          </option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="others">Others</option>
        </select>
      </div>
    );
  }

  // Handling general input
  return (
    <div className="flex flex-col w-full ">
      {label && (
        <label className="text-white font-medium p-2">
          {label} {required && <span className="required-asterisk">*</span>}
        </label>
      )}
      <input
        required={required}
        onClick={onClick}
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        name={name}
        className="input"
      />
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default Input;
