import bg from "../assets/adani/cam-bg.png";
import Navbar from "../Navbar/Navbar";

function Loading({ image, city }) {
  return (
    <>
      <div className="flex flex-col items-center">
        <img
          src={image}
          alt="loading"
          className="transform scale-x-[-1] absolute top-[13vh] h-[56%] w-[90%] object-cover"
        />
      </div>

      <div
        className="h-screen relative flex flex-col bg-cover bg-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <Navbar city={city} />
      </div>

      <div className="text-[40px] font-normal text-[#e7a33e] text-center absolute bottom-[20%] left-[25%] ">
        <span>Please Wait</span> <br />
        your photo is processing
      </div>
    </>
  );
}

export default Loading;
