import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import bg from "../assets/adani/cam-bg.png";
import Navbar from "../Navbar/Navbar";
import { registerUserService } from "../Service/RegisterService";

function Camera() {
  const navigate = useNavigate();
  const [image, setImage] = useState(null);
  const location = useLocation();
  const { name, email, phone } = location.state;

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = () => setImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleApi = async (image) => {
    try {
      const data = await registerUserService({
        name: name,
        email: email,
        phone: phone,
        file: image,
      });

      if (data?.image_url) {
        navigate("/thankyou", { state: { s3_link: data.image_url } });
      }
    } catch (error) {
      console.log(error);
      toast("Server is down, please try again later");
    }
  };

  const handleProceed = async () => {
    await handleApi(image);
  };

  const handleReset = async () => {
    setImage(null);
  };

  if (image) {
    return (
      <>
        <div className="flex flex-col items-center">
          <img
            src={image}
            alt="Captured"
            className="absolute top-[27vh] w-[73%] object-cover rounded-lg border-2 border-[#000000] m-auto aspect-square"
          />
        </div>
        <div
          className="h-screen relative flex flex-col bg-cover bg-center"
          style={{ backgroundImage: `url(${bg})` }}
        >
          <Navbar />
        </div>
        <div className="box-border absolute bottom-[3%] flex justify-center items-center gap-[10em] w-full px-10">
          <button className="btn" onClick={handleReset}>
            Retake
          </button>
          <button className="btn" onClick={handleProceed}>
            Submit
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen">
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleImageChange}
          className="hidden"
          id="cameraInput"
        />
        <label htmlFor="cameraInput" className="btn w-[250px] ">
          <p className="text-center text-black text-xl font-bold">Camera</p>
        </label>
      </div>
    </>
  );
}

export default Camera;
