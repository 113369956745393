import React from "react";
import { useNavigate } from "react-router-dom";

function TermsAndConditions() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-900 text-white px-4">
      <h1 className="text-4xl font-bold mb-6">Terms & Conditions</h1>
      <p className="text-lg mb-8 text-center">
        By using this application, you agree to allow us to use your submitted
        photos for processing and storage. All data will be handled in
        accordance with our privacy policy.
      </p>
      <button
        onClick={() => navigate(-1)}
        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
      >
        Back
      </button>
    </div>
  );
}

export default TermsAndConditions;
