import React from "react";
import { useNavigate } from "react-router-dom";
import "react-simple-keyboard/build/css/index.css";
import { toast } from "react-toastify";
import Button from "../Button/Button";
import Input from "../Input/Input";
import Navbar from "../Navbar/Navbar";

function RegisterForm() {
  const navigate = useNavigate();
  const [form, setForm] = React.useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isChecked, setIsChecked] = React.useState(false);

  const validateName = (name) => name.trim().length >= 3;
  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePhone = (phone) => /^[0-9]{10}$/.test(phone);

  const onClick = async () => {
    const { name, email, phone } = form;

    if (!validateName(name)) {
      toast.error("Name must be at least 3 characters long");
      return;
    }

    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address");
      return;
    }

    if (!validatePhone(phone)) {
      toast.error("Phone number must be exactly 10 digits");
      return;
    }

    if (!isChecked) {
      toast.error("You must agree to the Terms & Conditions");
      return;
    }

    navigate("/camera", {
      state: { name, email, phone },
    });
  };

  const onChange = (name, value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="flex flex-col">
      <Navbar />
      <div className="card">
        <h1 className="text-4xl text-center text-white w-full capitalize sm:text-5xl md:text-5xl lg:text-5xl font-bold">
          REGISTER
        </h1>
        <Input
          label="Name"
          placeholder="Enter your name"
          name="name"
          value={form.name}
          onChange={(_, value) => onChange("name", value)}
        />
        <Input
          label="Email"
          placeholder="Enter your email"
          name="email"
          value={form.email}
          onChange={(_, value) => onChange("email", value)}
        />
        <Input
          label="Mobile Number"
          placeholder="Enter your mobile number"
          name="phone"
          value={form.phone}
          onChange={(_, value) =>
            onChange("phone", value.replace(/[^0-9]/g, "").slice(0, 10))
          }
        />
        <div className="flex items-center mt-4">
          <input
            type="checkbox"
            id="terms"
            className="mr-2"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label htmlFor="terms" className="text-white font-medium">
            By checking this box, you agree to the{" "}
            <span
              onClick={() => navigate("/terms")}
              className="text-blue-400 font-bold cursor-pointer underline"
            >
              Terms & Conditions
            </span>
          </label>
        </div>
        <Button label="NEXT" onClick={onClick} disabled={!isChecked} />
      </div>
    </div>
  );
}

export default RegisterForm;
