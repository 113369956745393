export const convertBase64ToFile = (base64Image, fileName) => {
  try {
    // Remove the data URL part (e.g., "data:image/jpeg;base64,")
    const byteString = atob(base64Image.split(",")[1]);

    // Extract file type from Base64 string
    const mimeType = base64Image.match(/data:(image\/[a-zA-Z]+);base64,/)[1];

    const byteArrays = [];
    for (let offset = 0; offset < byteString.length; offset += 1024) {
      const slice = byteString.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, fileName, { type: mimeType });
  } catch (error) {
    console.error("Error converting Base64 to file:", error);
    return null;
  }
};

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
