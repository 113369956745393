import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Camera from "./Pages/Camera";
import Latest from "./Latest";
import Loading from "./Pages/Loading";
import RegisterForm from "./Pages/Register";
import Thankyou from "./Pages/Thankyou";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
  return (
    <Router>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<RegisterForm />} />
        <Route path="/camera" element={<Camera />} />
        <Route path="/thankyou" element={<Thankyou />} />
        <Route path="/latest" element={<Latest />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
    </Router>
  );
}

export default App;
